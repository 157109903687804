import * as React from "react"
import Layout from '../components/layout/layout'
import * as styles from '../components/index.module.css'
import JoinPropme  from "../components/joinpropme/joinpropme";
import 'bootstrap/dist/css/bootstrap.min.css';
import landingimg from '../images/socialgaming.png'
import propmeimg from '../images/propmevideoimg.png'
import Keyfeatures from "../components/keyfeatures/keyfeatures";
import StayUpdated from '../components/stayupdated/stayupdated';
import Contact from "../components/contactus/contactus";

class Index extends React.Component{
  constructor(props){
    super(props);
    this.state={

    }
  }
  render(){
    return(
      <Layout>
        <div className={styles.landingsection}>
          <div className="row" id={styles.landingsectionRow}>
            <div className="col-lg-8" id={styles.landingsectionCol}>
              <div className={styles.col2}>
                <p className={styles.landingheading}>Social Gaming Platform:</p>
                <p className={styles.landingheading1}>Peer-to-peer and eSports</p>
                <p className={styles.landingsubdesc}>A West Virgina-based innovative betting platform that has p2p, group betting and eSports. DeFi and NFTs - COMING SOON</p>
                <button className={styles.downloadbutton}>DOWNLOAD PROPME</button>
              </div>
            </div>
            <div className="col-lg-4" id={styles.landingsectionCol}>
              <div className={styles.col2}>
                <img className={styles.landingsideimg} src={landingimg} alt="landingimg" />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.whypropme}>
          <text className={styles.propmeheading}>Why PropMe?</text>
          <text className={styles.propmedesc}>PropMe truly stands out as a first-of-its-kind marketplace, built from the ground up to facilitate user-friendly P2P betting – no sportsbook, complicated odds, or DFS rosters. PropMe makes betting easy for new and casual gamblers, as well as seasoned players, through its use of straightforward proposition bets. Watch this video to know more!</text>
          <iframe src="https://www.youtube.com/embed/oKFtjacuTDU" frameborder="0" allowfullscreen="" id="1977951806" className={styles.propmeimg}></iframe>
        </div>
        <Keyfeatures />
        <div className={styles.about}>
          <text className={styles.aboutheading}>About Us</text>
          <text className={styles.aboutsubheading}>PropMe is an innovative eSports and Peer-to-Peer Gaming Platform</text>
          <p className={styles.aboutdesc}>We are a West Virginia-based innovative online social betting company. We bring peer-to-peer (P2P) betting platform to market.</p>
          <p className={styles.aboutdesc}>PropMe is committed to innovating in the online gaming space. If you want the traditional sportsbook experience with lines, odds and a bar with large TVs on the wall, you came to wrong place. </p>
          <p className={styles.aboutdesc}>We want to create new user experiences for a new audience!</p>
          <p className={styles.aboutdesc}>We're working hard to bring new ways to play!</p>
        </div>
        <JoinPropme />
        <Contact />
        <StayUpdated/>
      </Layout>
    )
  }
}

export default Index
